<template>
    <div>
        <div class="pb-7">
            <HeaderPage />
        </div>
        <div class="pt-8 pb-4 pl-4">
            <h2 class="class-title">{{ $t("message.title-order-history") }}</h2>
        </div>
        <keep-alive>
            <div>
                <div v-if="isLoading">
                    <div v-for="i in 10" :key="i">
                        <SkeletonProduct />
                    </div>
                </div>

                <div v-else>
                    <div v-if="orderHistoryNew.length === 0">
                        <v-container class="pb-1">
                        <div class="d-flex flex-row align-center justify-center mt-12">
                            <h3 style="color: grey">
                            <!-- {{$t("message.no-have-product-history-store")}} -->
                            {{ $t("message.no-purchase-history") }}
                            </h3>
                        </div>
                        </v-container>
                    </div>


                    <div
                        v-else
                        class="class-invoice"
                        v-for="(order, i) in orderHistoryNew"
                        :key="i"
                    >
                        <div class="pl-3 pt-4" style="display: flex; justify-content: space-between">
                            <h4 class="m-4">{{ order.order_date }}</h4>
                            <p style="padding: 0 10px; cursor: pointer" @click="getLink(order)">
                                詳細を見る
                                <v-icon style="color: black; width: 0.8em">{{ svgPath }}</v-icon>
                            </p>
                        </div>
                        <v-container style="">
                            <v-card
                                v-for="(item, idx) in order.detail"
                                :key="idx"
                                class="odd_even mb-3"
                                flat
                            >
                                <ListProduct :data="item" :store="order" @data-slug="getModal" />
                            </v-card>
                        </v-container>
                    </div>

                    <div style="margin-bottom: 12rem">
                        <v-pagination
                        v-if="!isLength"
                        color="#ff0090"
                        v-model="pageOrder"
                        :total-visible="5"
                        :length="orderHistoryHeader.last_page"
                        @input="nextPageOrder"
                        ></v-pagination>
                    </div>
                </div>
            </div>
        </keep-alive>
        <DetailModal
            :item_slug="slug"
            :showDialog="dialog"
            @get-modal="handleModal"
            @dialogVisible="dialog"
        />

        <ConfirmationProduct
            :data_product="data_dialog_confirm"
            :showDialog="dialog_confirmation_product"
            @close="getCloseModal"
            @dialogVisible="dialog_confirmation_product"
        />

        <Warning
            :openDialog="dialog_warning"
            @handle-dialog="handleDialog_warning"
        />
        <Cashier />
        <div>
            <Sidebar />
        </div>
    </div>
</template>

<script>
import { mdiChevronRightCircleOutline as chevron_right } from "@mdi/js";
import Warning from '../../components/Warning.vue'
import ConfirmationProduct from '../../components/ConfirmationProduct.vue'
import DetailModal from '../../components/DetailModal.vue'
import HeaderPage from '../../components/developmentv2/HeaderPage.vue'
import Sidebar from '../../components/developmentv2/Sidebar.vue'
import Cashier from '../../components/Cashier.vue'
import ListProduct from '../../components/ListProduct.vue'
import SkeletonProduct from "../../components/skeleton/SkeletonProduct.vue";
export default {
    name: "OrderHistory",
    components: {
        SkeletonProduct,
        ListProduct,
        Cashier,
        Sidebar,
        HeaderPage,
        DetailModal,
        ConfirmationProduct,
        Warning
    },
    data() {
        return {
            svgPath: chevron_right,
            isLoading: false,
            pageOrder: 1,
            dialog: false,
            dialog_confirmation_product: false,
            data_dialog_confirm: {},
            data_dialog_warning: null,
            dialog_warning: false,
            slug: ""
        }
    },

    methods: {
        getModal(data) {
            if(typeof data === 'string') {
                this.slug = data;
                this.dialog = true;
            } else {
                this.data_dialog_confirm = data
                this.dialog_confirmation_product = true
            }
        },
        handleModal(data) {
            if(data[0] == 'ConfirmProduct') {
                this.data_dialog_confirm = data[1]
                this.dialog_confirmation_product = true
            } else if(data[0] == 'dialog_alcohol') {
                this.data_dialog_warning = data[1]
                this.dialog_warning = true
            } else {
                this.dialog = false
            }
        },
        getCloseModal() {
            this.dialog = false
            this.dialog_confirmation_product = false
        },
        handleDialog_warning(val) {
            if (val.selected == "yes") {
                this.message.title = this.$t("message.title-add-carts");
                this.$store.dispatch("carts_module/addCarts", {
                itemId: this.data_dialog_warning.id,
                storeId: this.data_dialog_warning.store_id,
                message: {
                    title: this.message.title,
                },
                });
                setTimeout(() => {
                this.dialog = false;
                }, 1800)
            }
            this.dialog_warning = false;
        },
        async dispatchOrderHistoryNew() {
            this.isLoading = true;
            let res = await this.$store.dispatch("order_module/fetchOrderHistoryNew", {
                page: this.pageOrder,
            });
            this.isLoading = false;
            return res
        },
        async nextPageOrder() {
            await this.$store.dispatch("order_module/fetchOrderHistoryNew", {
                page: this.pageOrder,
            });
        },
        getLink(order) {
            this.$router.push("/order-history/" + order.id);
        },
    },
    created() {
        this.dispatchOrderHistoryNew()
    },
    computed: {
        isLength() {
            return this.$store.state.order_module.is_length
        },
        orderHistoryNew() {
            return this.$store.state.order_module.orderHistoryNew
        },
        orderHistoryHeader() {
            return this.$store.state.order_module.orderHistoryNewHeader
        }
    }
};
</script>

<style>
.class-invoice {
    width: 100%;
    height: auto;
    background-color: #ece8e4;
    margin: 0 0 30px 0;
    border-radius: 10px;
}
.class-title {
    border-left: 10px solid #ff0090;
    /* font-size: 16px; */
    color: #424242;
    padding-left: 5px;
}
</style>
